import { PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Tabs from "../../../components/Tabs"
import TemplatePage from "../../../components/TemplatePage"
import ActeNormative from "../../../components/legislatie/ActeNormative"
import TabelRapoarte from "../../../components/legislatie/Rapoarte"
import {
  ActNormativ,
  getAllActeNormative,
} from "../../../utils/api/acteNormative"

export default function Legislatie({ location }: PageProps) {
  const [tabValue, setTabValue] = React.useState(0)
  const [items, setItems] = React.useState<ActNormativ[]>([])

  React.useEffect(() => {
    const getActe = async () => {
      try {
        const apiResponse = await getAllActeNormative()
        if (apiResponse.type) {
          setItems(apiResponse.data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getActe()
  }, [])

  return (
    <TemplatePage
      showDash={false}
      additionalClassName="templateFullContainerFaqScreen"
      location={location}
      title="Legislație"
      topPaneHeight={80 + 56}
      additionalTopPaneElement={
        <Tabs
          setTabValue={setTabValue}
          tabValue={tabValue}
          tabs={["Acte normative", "Rapoarte", "Proceduri"]}
        />
      }
    >
      <Helmet>
        <title>Legislatie - Acte Normative - Gazmir</title>
        <meta
          name="description"
          content="Vezi ce Legislatie este aplicabila sectorului de gaze naturale. Afla actele normative ce stau la baza activitatii de furnizare a gazelor naturale."
        />
        <meta
          name="keywords"
          content="Legislatie gaz,Legislatie gaze naturale,Gazmir legislatie,Legi gaz,Legi gaze naturale,Lege gaz"
        />
      </Helmet>
      {tabValue === 0 && (
        <ActeNormative items={items.filter(item => item.tip === 0)} />
      )}
      {tabValue === 1 && (
        <TabelRapoarte items={items.filter(item => item.tip === 1)} />
      )}
      {tabValue === 2 && (
        <ActeNormative items={items.filter(item => item.tip === 2)} />
      )}
    </TemplatePage>
  )
}
